import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    title: String,
    series: Array,
    threshold: Object,
  }
  static targets = ["container"]
  initialize() {
    Highcharts.chart(this.containerTarget, {
      title: { text: this.titleValue },
      chart: { type: "line" },
      legend: { enabled: true },
      styledMode: true,
      xAxis: {
        labels: { format: "{value:%b %e}" },
        type: "datetime",
      },
      yAxis: {
        plotLines: this.thresholdValue ? [{
          color: this.thresholdValue.color,
          value: this.thresholdValue.value,
          width: 2,
          label: {
            text: this.thresholdValue.name + ': ' + this.thresholdValue.value,
            useHTML: true,
            style: {
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '4px',
              border: '1px solid #ccc',
            },
            y: 15,
            x: 0,
            verticalAlign: 'bottom',
          },
        }] : undefined,
        min: 0,
        max: 100,
      },
      series: this.seriesValue,
      plotOptions: {
        series: {
          states: {
            inactive: { opacity: 1 }
          }
        }
      }
    });
  }
}
