import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    mode: String,
  }

  connect() {
    this.flatpickrInstance = flatpickr(this.inputTarget, { mode: this.modeValue || "range" });
  }

  disconnect() {
    this.flatpickrInstance.destroy();
  }
}
