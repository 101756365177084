import { Controller } from 'stimulus'
import Highmaps from 'highcharts/highmaps'

/*
 * %div{data: {'controller' => 'choropleth', 'choropleth-features-value' => raw(@map_data), 'choropleth-title-value' => 'Enrollments'}}
 *   %div{data: {'choropleth-target' => 'map'}}
 */

export default class extends Controller {
  static values = {
    features: Array,
    title: String,
    town: String,
    nav: Boolean,
    labels: Array,
  }
  static targets = [ "map" ]

  initialize() {
    this.renderContent()
  }

  renderContent() {
    const params = new URLSearchParams(window.location.search)
    const geoData = {
      "type": "FeatureCollection",
      "name": "geojson",
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": this.featuresValue
    }
    const maxValue = Math.ceil(Math.max(...geoData.features.map(t => t.value)) / 10) * 10
    const targetTown = this.townValue || null
    const labeledTowns = this.labelsValue
    const map = Highmaps.mapChart(this.mapTarget, {
      title: {
        text: this.titleValue
      },
      caption: {
        text: 'Enrollments per 10,000 residents',
        align: 'center',
      },
      mapView: {
        projection: {
          name: 'WebMercator',
        },
      },
      mapNavigation: {
        enabled: this.navValue,
        buttonOptions: {
          verticalAlign: 'top',
        },
        enableMouseWheelZoom: false,
      },
      plotOptions: {
        map: {
          borderColor: '#fff',
        },
      },
      series: [{
        name: 'Municipalities',
        data: this.featuresValue,
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          formatter: function() {
            if (targetTown == this.point.name) {
              return this.point.name
            }
            if (labeledTowns.includes(this.point.name)) {
              return this.point.name
            }
          },
        },
        states: {
          hover: {
            borderColor: '#000',
            brightness: 0,
          },
          select: {
            color: '#f00',
          },
        },
      }],
      tooltip: {
        backgroundColor: '#fff',
        formatter: function() {
          let elements = []
          elements.push(`<b>${this.point.name}</b><br />`)
          elements.push(`Enrollments: ${this.point.enrollments.toLocaleString()}`)
          elements.push(`Per ten thousand: ${this.point.value.toLocaleString()}`)
          elements.push(`Population: ${this.point.pop2010.toLocaleString()}<br />`)
          return elements.join('<br />')
        },
      },
      colorAxis: {
        endOnTick: true,
        min: 0,
        maxColor: '#002266',
        dataClasses: [
          {
            to: 1,
          },
          {
            from: 1,
            to: maxValue * .2,
          },
          {
            from: 1 + (maxValue * .2),
            to: maxValue * .4,
          },
          {
            from: 1 + (maxValue * .4),
            to: maxValue * .6,
          },
          {
            from: 1 + (maxValue * .6),
            to: maxValue * .8,
          },
          {
            from: 1 + (maxValue * .8),
            to: maxValue,
          },
        ],
      },
    })
    if ( targetTown ) {
      const selectedTown = map.series[0].points.filter( function(point) {
        return point.name == targetTown
      })
      if ( selectedTown[0] ) {
        selectedTown[0].select()
      }
    }
  }
}
