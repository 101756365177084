import { Controller } from "stimulus";
import consumer from "../channels/consumer";

// controller for receiving DOM updates via ActionCable.
export default class extends Controller {
  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: this.element.dataset.cableRefreshChannel,
        id: this.element.dataset.cableRefreshId,
      },
      {
        received: (data) => {
          this.element.innerHTML = data.html;
          // console.log("data", data);
        },
      }
    );
    // console.log("this.subscription", this.subscription);
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }
}
