import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileInput"];

  connect() {}

  selectFile(event) {
    event.preventDefault();
    $(this.fileInputTarget).click();
    event.currentTarget.blur();
  }

  submit(_event) {
    this.element.submit();
  }
}
