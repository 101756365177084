function requireAll(r) {
  r.keys().forEach(r);
}

// Vendored Styles
require("./vendor/bootstrap.scss");
require("./vendor/flatpickr.scss");
require('./vendor/ma_brand_banner.scss');
require('./vendor/ma_footer_slim.scss');

// Application Styles
requireAll(require.context("./layouts", true, /\.scss|\.sass|\.css/));
requireAll(require.context("./components", true, /\.scss|\.sass|\.css/));
requireAll(require.context("./utilities", true, /\.scss|\.sass|\.css/));
