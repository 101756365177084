import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const otherCheckboxIdValue = this.element.dataset.otherCheckboxId
    const otherInputIdValue = this.element.dataset.otherInputId
    const showElement = this.showElement

    const $otherCheckbox = $(`#${otherCheckboxIdValue}`)
    const $otherInput = $(`#${otherInputIdValue}`)

    showElement($otherInput, false)
    $otherCheckbox.removeAttr('name')
    $otherCheckbox.on("change", function(event) {
      showElement($otherInput, event.target.checked)
    });
  }

  showElement($element, show) {
    $element.parent().prop('hidden', !show)
    $element.prop('disabled', !show)
  }
}
