import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const id = this.element.dataset.id;
    const otherValue = this.element.dataset.otherValue;
    const setInputs = this.setInputs;
    const $select = $(`#${id}`);
    const $input = $(`#${id}_other`);
    setInputs($select, $input);

    const options = $(`#${id} option`)
      .toArray()
      .filter(element => $(element).val().length > 0 && $(element).val() != otherValue);

    if(options.length == 0) {
      setInputs($input, $select);
    }

    $select.on("change", function() {
      if($select.val()==otherValue) {
        setInputs($input, $select);
        $input.val('');
        $input.focus();
        $select.val([]);
      }
    });

    $input.on("blur", function() {
      if($input.val().trim().length == 0 && options.length > 0) {
        setInputs($select, $input);
      }
    });
  }

  setInputs($enable, $disable) {
    $enable.parent().prop('hidden', false);
    $enable.prop('disabled', false);
    $disable.parent().prop('hidden', true);
    $disable.prop('disabled', true);
  }
}
