import { Controller } from 'stimulus'
import Highmaps, { getRendererType } from 'highcharts/highmaps'



export default class extends Controller {
  static values = {
    outlinesMapData: Array,
    outlinesMapDataLabel: String,
    filledMapData: Array,
    filledMapDataLabel: String,
    title: String,
    nav: Boolean,
    captionText: String,
    selectedZipCode: String,
  }
  static targets = ["map"]

  initialize() {
    this.renderContent()
  }

  formatterFunction() {

    var msg = `<b>${this.point.category}: ${this.point.name}</b>`;

    if (this.point.category !== 'County') {
      msg += `
        <br />
      `
      if (this.point.average_fmr) {
        msg += `
        <br />
          Average FMR: ${this.point.average_fmr}<br />
          Average Rent: ${this.point.average_rent}<br />
          Average Rent / Average FMR: ${this.point.value}<br />
          `
      }
      msg += `
        <br />
        sro_fmr: ${this.point.sro_fmr}<br />
        esro_fmr: ${this.point.esro_fmr}<br />
        efficiency_or_studio_fmr: ${this.point.efficiency_or_studio_fmr}<br />
        one_bedroom_fmr: ${this.point.bdrm_1_fmr}<br />
        two_bedroom_fmr: ${this.point.bdrm_2_fmr}<br />
        three_bedroom_fmr: ${this.point.bdrm_3_fmr}<br />
        four_bedroom_fmr: ${this.point.bdrm_4_fmr}<br />
        five_bedroom_fmr: ${this.point.bdrm_5_fmr}<br />
        six_bedroom_fmr: ${this.point.bdrm_6_fmr}<br />
      `;
    }

    return msg;
  }

  colorAxis() {
    return {
      dataClasses: [
        { color: '#D3D3D3', to: 0 },
        { color: '#0091D5', from: 0.00, to: 0.85 },
        { color: '#A5D8DD', from: 0.85, to: 0.95 },
        { color: '#6AB187', from: 0.95, to: 1.05 },
        { color: '#DBAE58', from: 1.05, to: 1.15 },
        { color: '#D32D41', from: 1.15 },
      ],
    };
  }

  renderContent() {
    const selectedZipCodeValue = this.selectedZipCodeValue || null
    const map = Highmaps.mapChart(this.mapTarget, {
      title: {
        text: this.titleValue
      },
      caption: {
        text: this.captionTextValue,
        align: 'center',
      },
      mapView: {
        projection: {
          name: 'WebMercator',
        },
      },
      mapNavigation: {
        enabled: this.navValue,
        buttonOptions: {
          verticalAlign: 'top',
        },
        enableMouseWheelZoom: false,
      },
      plotOptions: {
        map: {
          borderColor: '#fff',
        },
      },
      series: [
        {
          zIndex: 0,
          name: this.filledMapDataLabelValue,
          data: this.filledMapDataValue,
          allowPointSelect: true,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.point.name == selectedZipCodeValue) {
                return this.point.name
              }
            },
          },
          states: {
            hover: {
              borderColor: '#000',
              brightness: 0,
            },
            inactive: {
              opacity: 1
            },
            select: {
              color: '#f00',
            },
          },
        },
        {
          colorAxis: false,
          zIndex: 1,
          type: 'mapline',
          name: this.outlinesMapDataLabelValue,
          data: this.outlinesMapDataValue,
          allowPointSelect: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.point.name
            },
          },
          states: {
            hover: {
              borderColor: '#000',
              brightness: 0,
            },
            inactive: {
              opacity: 1
            },
          },
        },
      ],
      tooltip: {
        colorAxis: false,
        backgroundColor: '#fff',
        formatter: this.formatterFunction,
      },
      colorAxis: this.colorAxis(),
    })
    if (selectedZipCodeValue) {
      const selectedZipCode = map.series[0].points.filter(function (point) {
        return point.name == selectedZipCodeValue
      })
      if (selectedZipCode[0]) {
        selectedZipCode[0].select()
      }
    }
  }
}
