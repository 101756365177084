import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['form', 'pageInput']

  connect() {}

  submitForm(event){
    event.preventDefault();
    const url = new URL(event.target.getAttribute('href'), window.location)
    const pageInput = this.pageInputTarget
    pageInput.value = url.searchParams.get('page')
    this.formTarget.requestSubmit()
  }
}
